import React from "react"
import "../styles/TeamValues.scss";

function TeamValues() {
  return (
    <section className="team-values">
      <h2 className="team-values__title">
        Core Values
      </h2>
      <p>
        These are the core values of Deft. We strive to make going into work invigorating and to help people reach the next level of their careers. To help us focus on what matters we take care of as much other stuff as possible for our employees. Our benefits are pretty exceptional.
      </p>
      <ul className="team-values__groups">
        <li>
          <h3 className="team-values__group-title">We want people that are</h3>
          <ul className="team-values__group-list">
            <li>Altruistic</li>
            <li>Biased towards action</li>
            <li>Compassionate</li>
            <li>Humble</li>
            <li>Vulnerable</li>
          </ul>
        </li>
        <li>
          <h3 className="team-values__group-title">To provide opportunites for</h3>
          <ul className="team-values__group-list">
            <li>Mastery</li>
            <li>Autonomy</li>
            <li>Purpose</li>
          </ul>
        </li>
        <li>
          <h3 className="team-values__group-title">To build a team that is</h3>
          <ul className="team-values__group-list">
            <li>Inclusive</li>
            <li>Personal</li>
            <li>Supportive</li>
            <li>Constructive through conflict</li>
          </ul>
        </li>
        <li>
          <h3 className="team-values__group-title">So we can achieve</h3>
          <ul className="team-values__group-list">
            <li>Personal growth</li>
            <li>Economic security</li>
            <li>Lifelong relationships</li>
          </ul>
        </li>
      </ul>
    </section>
  )
}

export default TeamValues;