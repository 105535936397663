import React from "react";
import { Link, graphql } from "gatsby";
import Image from "gatsby-image";
import "../styles/TeamMemberBio.scss";

class TeamMemberBio extends React.Component {
  render() {
    /* Consumers of TeamMemberBio must make a MarkdownRemark query on the TeamMemberBio fragment exported below.
    query {
      markdownRemark {
        ...TeamMemberBio
      }
    }

    If refactoring in the future see if we can change this to a parameterized static query if the feature proposed
    here (https://github.com/gatsbyjs/gatsby/issues/10482) has been implemented.
    */

    const { teamMember } = this.props;
    return (
      <section className="team-member-bio">
        <header className="team-member-bio__header">
          <Image className="team-member-bio__pic" fluid={teamMember.frontmatter.image.childImageSharp.fluid} />
          <Link className="team-member-bio__name-link" to={`/blog/author/${teamMember.fields.slug}`}>
            <h4 className="team-member-bio__name">
              {teamMember.frontmatter.name}
            </h4>
          </Link>
          <div className="team-member-bio__metadata">
            {teamMember.frontmatter.pronouns && (
              <span>
                {teamMember.frontmatter.pronouns}
              </span>
            )}
            {teamMember.frontmatter.position && (
              <span>
                {teamMember.frontmatter.position}
              </span>
            )}
          </div>
        </header>
        <div className="team-member-bio__description" dangerouslySetInnerHTML={{ __html: teamMember.html }} />
      </section>
    )
  }
}

export default TeamMemberBio

export const query = graphql`
  fragment TeamMemberBio on MarkdownRemarkConnection {
    edges {
      node {
        html
        fields {
          slug
        }
        frontmatter {
          name
          pronouns
          position
          status
          image {
            childImageSharp {
              fluid(maxWidth: 200, maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;