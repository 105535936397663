import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import TeamPhoto from "../../content/assets/images/deft-team.jpg";
import PageHero from "../components/PageHero";
import PageContent from "../components/PageContent";
import TeamValues from "../components/TeamValues";
import TeamMemberBio from "../components/TeamMemberBio";
import "../styles/pages/team.scss";

class Team extends React.Component {
  render() {
    const { data } = this.props;
    const pageName = "team";
    const teamMembers = data.allMarkdownRemark.edges.map(edge => edge.node)
    //Teammembers appear alphabetically by first name, but move the CEO to the front
    teamMembers.splice(0, 0, teamMembers.splice(teamMembers.findIndex(teamMember => teamMember.frontmatter.position === `CEO`), 1)[0]);
    console.table(teamMembers)
    return (
      <Layout location={this.props.location} pageName={pageName}>
        <SEO
          title="Team"
          keywords={[`software engineer`, `development team`, `javascript`, `react`, `ruby on rails`, `dev ops`, `Deft staff`]}
        />
        <PageHero title="Our Team">
          <div className="page-hero__photo-background" style={{backgroundImage: `url(${TeamPhoto})`}} />
        </PageHero>
        <PageContent>
          <TeamValues />
          {teamMembers.filter(teamMember => teamMember.frontmatter.status === "current").map((node) => {
            return (
              <TeamMemberBio teamMember={node} key={node.fields.slug} />
            )
          })}
        </PageContent>
      </Layout>
    )
  }
}

export default Team

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { collection: { eq: "authors" }, fields: { slug: { ne: "/default/" } } },
      sort: { fields: [frontmatter___name], order: ASC }
    ) {
      ...TeamMemberBio
    }
  }
`
